@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Martian+Mono:wght@100..800&family=Montserrat:wght@700&family=Outfit:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Martian+Mono:wght@100..800&family=Montserrat:wght@700&family=Outfit:wght@100..900&family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Sora:wght@100..800&display=swap');
body {
  font-family: 'Outfit',sans-serif;
}

.chat-container {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  opacity: 0; 
  flex-direction: column-reverse;
  transition: opacity 0.5s ease-in-out;
  overflow-y: auto; /* Enable scrolling by default */
  overflow-x: hidden;  /* Hide horizontal scrollbar if needed */ 
}


.chat-container::-webkit-scrollbar {
  display: none; /* WebKit (Chrome, Safari, etc.) */
}

.chat-container.loaded {
  opacity: 1;
}

.chat-input-container {
  opacity: 0;
  transition: opacity 0.5s ease-in-out; /* Delay the transition by 0.2s */
  /* ... other necessary input container styles */
}

.chat-input-container.loaded {
  opacity: 1;
}