@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Martian+Mono:wght@100..800&family=Montserrat:wght@700&family=Outfit:wght@100..900&display=swap');

body {
  font-family: 'Outfit',sans-serif;
}

@layer utilities {
      /* Hide scrollbar for Chrome, Safari and Opera */
      .no-scrollbar::-webkit-scrollbar {
          display: none;
      }
     /* Hide scrollbar for IE, Edge and Firefox */
      .extra-content {
          -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;  /* Firefox */
    }
    .messages-container {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }
  }

.chat-container {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  opacity: 0; 
  flex-direction: column-reverse;
  transition: opacity 0.5s ease-in-out;
  overflow-y: auto; /* Enable scrolling by default */
  overflow-x: hidden;
}


.chat-container::-webkit-scrollbar {
  display: none; /* WebKit (Chrome, Safari, etc.) */
}

.chat-container.loaded {
  opacity: 1;
}

.chat-input-container {
  opacity: 0;
  transition: opacity 0.5s ease-in-out; /* Delay the transition by 0.2s */
}

.chat-input-container.loaded {
  opacity: 1;
}